const BUILD_ENV = process.env.REACT_APP_NODE_ENV
  ? process.env.REACT_APP_NODE_ENV
  : 'local'
console.log({ BUILD_ENV })
//localhost-------------
let _routes = {
  LOGIN_ENDPOINT: 'http://localhost:7015/login',
  BASE_URL: 'http://localhost:3000',
  PATH: '/',
  BASE_URL_TEACH: 'http://localhost:3000',
  // BASE_URL_TEST_SERVER: "http://localhost:3008",
}

if (BUILD_ENV == 'testing') {
  //testing------------
  _routes = {
    ..._routes,
    LOGIN_ENDPOINT: 'https://pegasust.imarticus.org/login',
    BASE_URL: 'https://apiht.pegasust.imarticus.org',
    BASE_URL_TEACH: 'https://apiht.pegasust.imarticus.org',
    // BASE_URL_TEST_SERVER: "https://examt.pegasust.imarticus.org",
  }
} else if (BUILD_ENV == 'staging') {
  //staging------------
  _routes = {
    ..._routes,
    LOGIN_ENDPOINT: 'https://pegasuss.imarticus.org/login',
    BASE_URL: 'https://apihs.pegasuss.imarticus.org',
    BASE_URL_TEACH: 'https://apihs.pegasuss.imarticus.org',
    // BASE_URL_TEST_SERVER: "https://exams.pegasuss.imarticus.org",
  }
} else if (BUILD_ENV == 'production') {
  //production------------
  _routes = {
    ..._routes,
    LOGIN_ENDPOINT: 'https://pegasus.imarticus.org/login',
    BASE_URL: 'https://apih.pegasus.imarticus.org',
    BASE_URL_TEACH: 'https://apihteach.pegasus.imarticus.org',
    // BASE_URL_TEST_SERVER: "https://exam.pegasus.imarticus.org",
  }
}
console.log({ configs: _routes })
export default _routes
