import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './lib/fingerprint.js'
import App from './App'
import reportWebVitals from './reportWebVitals'
import configStore from './store'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'

const theme = createTheme({
  palette: {
    action: {
      // disabledBackground: 'set color of background here',
      disabled: '#000',
    },
  },
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
var store = configStore()
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
